import React from "react";
import { HiCheck } from "react-icons/hi";

const WhyProfessionalServices = ({
  title,
  subtitle,
  description,
  features,
}) => {
  return (
    <div className="bg-gray-50 pt-16 pb-12">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:px-0">
        <div>
          <h2 className="text-lg font-semibold text-cyan-600">{title}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
            {subtitle}
          </p>
          <p className="mt-4 text-lg text-gray-500">{description}</p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="flex flex-col gap-5">
            {features?.map((feature) => (
              <div key={feature.title} className="relative">
                <dt>
                  <HiCheck
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg font-medium leading-6 text-gray-900">
                    {feature.title}
                  </p>
                </dt>
                <dd className="ml-9 mt-1 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default WhyProfessionalServices;
