import React from "react";
import Hero from "../components/common/hero";
import { graphql } from "gatsby";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import WhyProfessionalServices from "../components/professionalServices/why";
import LocalizedLink from "../components/localizedLink";

const ProfessionalServices = ({ location, data: { page, partners } }) => {
  const { lang } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={page.seo.metaTitle}
        metas={[{ name: "description", content: page.seo.metaDescription }]}
      />
      <Hero title={page.header.title} subtitle={page.header.subtitle} />
      <div className="w-full bg-gray-50">
        <WhyProfessionalServices
          title={page.title}
          subtitle={page.subtitle}
          description={page.description}
          features={page.features}
        />
        <div className="relative max-w-5xl mx-auto">
          <div className="pt-4 mb-16 mx-auto grid gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-3 px-4 lg:px-0 lg:max-w-none">
            {partners.nodes.map((partner) => {
              return (
                <div className="flex bg-white flex-col rounded-lg shadow-md transition-all">
                  <div className="block h-full">
                    <div className="flex-1 h-full px-8 py-3 gap-8 flex flex-col items-center justify-between">
                      <div className="flex flex-col mt-4 gap-2">
                        <h3 className="text-xl font-semibold text-gray-900">
                          {partner.name}
                        </h3>
                        <span className="text-sm font-medium text-cyan-600">
                          {partner.shortDescription}
                        </span>
                        <span className="text-base mt-3 text-gray-500">
                          {partner.longDescription}
                        </span>
                      </div>
                      <div className="flex flex-col items-center w-full gap-3 mb-3">
                        <img
                          className="w-40 my-3"
                          src={partner.logo?.url}
                          alt={partner.logo?.alternativeText}
                        />
                        <a
                          href={partner.link?.href}
                          target={partner.link?.target}
                          className="text-sm text-cyan-700 hover:underline"
                        >
                          <div className="bg-cyan-100 py-1 px-3">
                            <span className="text-sm text-cyan-700">
                              {partner.link?.label}
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {page?.cta && (
          <div className="bg-cyan-700">
            <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span className="block">{page.cta.title}</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-cyan-200">
                {page.cta.description}
              </p>
              <LocalizedLink
                className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-cyan-600 hover:bg-cyan-50 sm:w-auto"
                to={page.cta.link?.href}
              >
                {page.cta.link?.label}
              </LocalizedLink>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const query = graphql`
  query StrapiProfessionalServicePage($locale: String) {
    page: strapiProfessionalServicePage(locale: { eq: $locale }) {
      header {
        subtitle
        title
      }
      seo {
        metaTitle
        metaDescription
      }
      title
      subtitle
      description
      features {
        title
        description
      }
      cta {
        title
        description
        link {
          href
          label
          target
        }
      }
    }
    partners: allStrapiPartner(
      sort: { fields: name, order: ASC }
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        shortDescription
        longDescription
        link {
          href
          label
          target
          isExternal
        }
        logo {
          alternativeText
          url
        }
        name
      }
    }
  }
`;

export default ProfessionalServices;
